import { GaugeOptions, ThresholdStep } from 'types';
import { PanelData, FieldConfigSource, createTheme } from '@grafana/data';

function round(number: number, places: number) {
  let factor = 10 ** places;
  return Math.round(number * factor) / factor;
}

function normaliseDataColumnIndex(value: number, data: PanelData) {
  let minColumns: number | null = null;
  data.series.forEach((series) => {
    let len = series.fields.length;
    if (minColumns === null || len < minColumns) {
      minColumns = len;
    }
  });
  let minIndex = minColumns ? minColumns - 1 : 0;
  return value > minIndex ? minIndex : value;
}

export function shouldShowThresholds(options: GaugeOptions, fieldConfig: FieldConfigSource<any>): boolean {
  return options.showThresholds === true && fieldConfig.defaults.thresholds?.mode === 'percentage';
}

export function getMaxValue(data: PanelData, options: GaugeOptions, raw = false): number {
  let rawValue = options.useDynamicMaxValue
    ? data.series[0].fields[normaliseDataColumnIndex(options.dataColumnIndex, data)].values.get(0)
    : options.staticMaxValue;
  return raw ? rawValue : round(rawValue, options.roundingDecimalPlaces);
}

export function getCurrentValue(data: PanelData, options: GaugeOptions, raw = false): number {
  let rawValue =
    data.series[options.useDynamicMaxValue ? 1 : 0].fields[
      normaliseDataColumnIndex(options.dataColumnIndex, data)
    ].values.get(0);
  return raw ? rawValue : round(rawValue, options.roundingDecimalPlaces);
}

export function getAvailableValue(data: PanelData, options: GaugeOptions, raw = false): number {
  let rawValue = Math.max(
    options.useDynamicMaxValue
      ? data.series[0].fields[normaliseDataColumnIndex(options.dataColumnIndex, data)].values.get(0) -
          data.series[1].fields[normaliseDataColumnIndex(options.dataColumnIndex, data)].values.get(0)
      : options.staticMaxValue -
          data.series[0].fields[normaliseDataColumnIndex(options.dataColumnIndex, data)].values.get(0),
    0
  );
  return raw ? rawValue : round(rawValue, options.roundingDecimalPlaces);
}

export function getCurrentValuePercentage(data: PanelData, options: GaugeOptions): number {
  return round(
    (getCurrentValue(data, options, true) / getMaxValue(data, options, true)) * 100,
    options.roundingDecimalPlaces
  );
}

export function getAvailableValuePercentage(data: PanelData, options: GaugeOptions): number {
  return round(
    (getAvailableValue(data, options, true) / getMaxValue(data, options, true)) * 100,
    options.roundingDecimalPlaces
  );
}

export function getCurrentValueThreshold(
  data: PanelData,
  options: GaugeOptions,
  fieldConfig: FieldConfigSource<any>
): ThresholdStep | null {
  if (fieldConfig.defaults.thresholds?.mode !== 'percentage') {
    return null;
  }
  let currentPercent = getCurrentValuePercentage(data, options);
  let returnValue = null;
  fieldConfig.defaults.thresholds?.steps.forEach((threshold: ThresholdStep) => {
    let thresholdPercent = isFinite(threshold.value) ? threshold.value : 0;
    if (currentPercent > thresholdPercent) {
      returnValue = threshold;
    }
  });
  return returnValue;
}

export function getCurrentValueColour(
  data: PanelData,
  options: GaugeOptions,
  fieldConfig: FieldConfigSource<any>
): string {
  if (!options.useThresholdColours) {
    return options.currentColour;
  }
  let threshold = getCurrentValueThreshold(data, options, fieldConfig);
  if (threshold === null) {
    return options.currentColour;
  } else {
    const theme = createTheme();
    const color = theme.visualization.getColorByName(threshold.color);
    return color;
  }
}

export function processLegendValue(value: string, data: PanelData, options: GaugeOptions): string {
  if (value === null || value === undefined) {
    return '';
  }
  value = value.replace(/{{ ?max ?}}/g, String(getMaxValue(data, options)));
  value = value.replace(/{{ ?current ?}}/g, String(getCurrentValue(data, options)));
  value = value.replace(/{{ ?available ?}}/g, String(getAvailableValue(data, options)));
  value = value.replace(/{{ ?current_percentage ?}}/g, String(getCurrentValuePercentage(data, options)));
  value = value.replace(/{{ ?available_percentage ?}}/g, String(getAvailableValuePercentage(data, options)));
  value = value.replace(/{{ ?unit ?}}/g, options.unit || '');
  return value;
}
