import { FieldConfigSource } from '@grafana/data';
import { GaugeOptions } from '../types';

export default function generateChartOptions(
  options: GaugeOptions,
  fieldConfig: FieldConfigSource<any>,
  showThresholds: boolean
) {
  return {
    maintainAspectRatio: false,
    responsive: true,
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    legend: { display: false },
    tooltips: {
      filter: (tooltipItem: any) => {
        return showThresholds ? tooltipItem.datasetIndex !== 0 : true;
      },
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let dataset = data.datasets[tooltipItem.datasetIndex];
          let index = tooltipItem.index;
          let value = `${dataset.data[index]}${options.addUnits ? ` ${options.unit}` : ''}`;
          if (dataset.labels !== undefined && dataset.labels[index] !== undefined) {
            return `${dataset.labels[index]}: ${value}`;
          } else {
            return value;
          }
        },
      },
    },
  };
}
