import { PanelData, FieldConfigSource, createTheme } from '@grafana/data';
import { GaugeOptions } from '../types';
import { getAvailableValue, getCurrentValue, getCurrentValueColour } from './Helpers';

export default function generateChartData(
  data: PanelData,
  options: GaugeOptions,
  fieldConfig: FieldConfigSource<any>,
  showThresholds: boolean
) {
  // Handle Thresholds
  let thresholdDataset = undefined;
  if (showThresholds) {
    thresholdDataset = {
      label: 'thresholds',
      labels: fieldConfig.defaults.thresholds?.steps.map((threshold: any, i: number) => {
        return i;
      }),
      data: fieldConfig.defaults.thresholds?.steps.map((threshold: any, i: number) => {
        let currentValue = isFinite(threshold.value) ? threshold.value : 0;
        let rawNextValue = fieldConfig.defaults.thresholds?.steps[i + 1]?.value || 100;
        let nextValue = isFinite(rawNextValue) ? rawNextValue : 0;
        return nextValue - currentValue;
      }),
      backgroundColor: fieldConfig.defaults.thresholds?.steps.map((threshold: any) => {
        const theme = createTheme();
        const color = theme.visualization.getColorByName(threshold.color);
        return color;
      }),
    };
  }

  // Handle Data
  let dataset = {
    label: 'data',
    labels: [options.currentValueLabel, options.availableValueLabel],
    data: [getCurrentValue(data, options), getAvailableValue(data, options)],
    backgroundColor: [getCurrentValueColour(data, options, fieldConfig), options.baseColour],
    weight: 7,
  };

  // Prepare Return
  let datasets = thresholdDataset === undefined ? [dataset] : [thresholdDataset, dataset];
  return { datasets: datasets };
}
