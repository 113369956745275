import React from 'react';
import { PanelProps } from '@grafana/data';
import { GaugeOptions } from 'types';
import { shouldShowThresholds, processLegendValue } from './utils/Helpers';
import generateChartOptions from 'utils/ChartOptions';
import generateChartData from 'utils/ChartData';
import { Doughnut } from 'react-chartjs-2';

import './styles.scss';

interface Props extends PanelProps<GaugeOptions> {}

export const GaugePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig }) => {
  const showThresholds: boolean = shouldShowThresholds(options, fieldConfig);
  const chartOptions = generateChartOptions(options, fieldConfig, showThresholds);
  const chartData = generateChartData(data, options, fieldConfig, showThresholds);
  const chartReference = React.createRef<Doughnut>();

  React.useEffect(() => {
    let chart = chartReference.current;
    if (chart === null) {
      return;
    }
    let canvas = chart.chartInstance.canvas;
    if (canvas === null) {
      return;
    }
    canvas.style.maxWidth = `${width}px`;
    canvas.style.maxHeight = `${height}px`;
  });

  return (
    <div className="rradar-gauge-panel" style={{ width: width, height: height, position: 'relative' }}>
      <div className="chart" style={{}}>
        <Doughnut ref={chartReference} data={chartData} options={chartOptions} />
      </div>
      <div className="legend">
        {options.showLegendHeading && (
          <div className="legend-heading">{processLegendValue(options.legendHeading, data, options)}</div>
        )}
        {options.showLegendSubheading && (
          <div className="legend-subheading">{processLegendValue(options.legendSubheading, data, options)}</div>
        )}
      </div>
    </div>
  );
};
